.smooth-custom-link {
  padding: 0 !important;
  margin: 0 !important;
  background-color: transparent !important;
  border: unset !important;
  width: 100%;
  height: 100%;
  cursor: pointer;

  span {
    pointer-events: none !important;
  }
}
