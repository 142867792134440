@import 'core/global';
@import 'core/variables';
@import 'core/mixins/colored-shadows';
@import 'core/mixins/animation';

// Core Components
@import 'core/misc';

// Core Plugins

// @import "plugins/plugin-datetime-picker";
@import 'plugins/plugin-nouislider';
@import 'plugins/plugin-react-datetime';
@import 'plugins/plugin-react-slick';
@import 'plugins/plugin-react-multi-date-picker';

input.form-control {
  background-image: none !important;
  background-size: unset !important;
  background-repeat: unset !important;
  background-position: unset !important;
  border-radius: 5px !important;
  box-shadow: 0 2px 2px 0px rgba(0, 0, 0, 0.14), 0 6px 30px 0px rgba(0, 0, 0, 0.12),
    0 8px 10px -10px rgba(0, 0, 0, 0.2) !important;
  cursor: pointer !important;
  padding: 7px !important;
  &:focus {
    background-image: none !important;
    background-size: unset !important;
  }
  &.text-center {
    text-align: center !important;
  }
}

.error-message {
  font-size: 12px;
  color: $red-500;
  font-style: italic;
  font-weight: 500;
  margin-top: -7px;
}

.authForm {
  margin: 0;
}

.rememberRadio {
  margin-top: 0.4rem !important;
  .blackLabel {
    color: $black-color;
  }
}

.tawk-min-container .tawk-button-circle.tawk-button-large {
  width: 50px !important;
  height: 50px !important;
}
