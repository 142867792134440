.suggested-product-block {
  width: 100% !important;
  flex-direction: column;

  .product-title {
    font-weight: bold;
  }

  .suggested-carousel {
    width: 100%;
    height: 460px;
    padding: 10px 0 !important;

    .suggested-slider {
      height: 100%;

      .suggested-item {
        width: 10px;
        height: 100%;
        padding: 0 15px !important;

        &.react-multi-carousel-item--active:first-child {
          padding-left: 0 !important;
        }

        &.react-multi-carousel-item--active:last-child {
          padding-right: 0 !important;
        }
      }
    }
  }
}

.suggested-subtitle {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  max-height: 60px;
}
