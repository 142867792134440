$space-1: 0.5rem;

/* MARGIN */
.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.mr-1,
.mx-1 {
  margin-right: space-1 !important;
}

.ml-1,
.mx-1 {
  margin-left: space-1 !important;
}

/* PADDING */
.p-0 {
  padding: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.pr-1,
.px-1 {
  padding-right: space-1 !important;
}

.pl-1,
.px-1 {
  padding-left: space-1 !important;
}
