$spacing-block: 1rem;
$horizontal-spacing: 15px;

.product-detail-page-component {
  background-color: white;

  .title-block {
    margin-bottom: $spacing-block !important;

    .title-container {
      padding-left: 0;

      @media (max-width: 959px) {
        padding: 0;
      }

      .txt-title {
        font-size: 3rem;
        font-weight: bold;
        line-height: 1.167;
      }
    }
  }

  .content-block {
    flex-wrap: wrap-reverse;
    margin-bottom: $spacing-block;

    .left {
      padding: 0;

      @media (min-width: 960px) {
        padding-right: $horizontal-spacing;
      }

      .detail-information-container {
        display: flex;
        flex-direction: column;
        position: relative;
        background-color: white;
        border: 1px solid #000;
        overflow: hidden;
        min-height: 140px;

        .detail-content {
          flex: 1;
          padding: 0.5rem;
          padding-bottom: 50px;
          height: 100%;

          > p {
            margin-bottom: 0;
          }
        }

        .btn-read-more {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 30px;
          color: black;
          box-shadow: 0 0 20px 0.5rem rgba(200, 200, 200, 0.8);
          margin: 0;
          cursor: pointer;
        }
      }
    }

    .right {
      margin-bottom: $spacing-block;
      padding: 0;

      .sticky-block {
        position: sticky;
        top: 6rem;
        transition: top 0.3s ease;
      }
    }
  }
}
