@import 'assets/scss/global-styles';

$slider-width: 180px;
$slider-height: 200px;
$animation-setup: 5s linear infinite alternate;
$border-radius: 30px;
$slider-img-padding: 3px;

.FullScreenVideoBanner {
  position: relative;

  &__VideoContainer {
    width: 100%;
    height: 100vh;

    &__Video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__ContentContainer {
    position: absolute;
    top: 0rem;
    left: 30px;
    right: 30px;
    bottom: 0;
    align-items: center;

    &__ContentLeft {
      padding: 50px 0;
      &__TextWhite {
        color: $white !important;
      }
    }

    &__ContentRight {
      position: relative;
      display: flex;
      justify-content: space-around;
      height: 400px;
      padding: 0;
      overflow: hidden;
      flex-wrap: wrap;
      border-radius: $border-radius;
      padding-left: 0 !important;
      padding-right: 0 !important;
      @media (max-width: 675px) {
        display: none;
      }

      &__SliderContainer-1,
      &__SliderContainer-2,
      &__SliderContainer-3 {
        width: 200px;
        height: 220px;
        border-radius: $border-radius;
        overflow: hidden;
      }

      &__SliderContainer-1 {
        background-color: #fff;
      }

      &__SliderContainer-2 {
        background-color: #fff;
        align-self: end;
      }

      &__SliderContainer-3 {
        align-self: center;
        background-color: #fff;
      }

      &__SliderContainer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        &__SwiperContainer {
          background-color: aqua;
          height: 160px;
        }

        &__Swiper {
          background-color: $gray-300;
          padding: $slider-img-padding;
          height: 100%;

          &__Slide {
            width: 100%;
            height: 100%;
            border-radius: $border-radius;
            overflow: hidden;

            &__Image {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        &__ButtonGroup {
          display: flex;
          flex: 1;
          justify-content: space-around;
          align-items: center;
          background-color: $gray-300;

          &__Text {
            margin: 0;
          }
          &__Button {
            background-color: #fff !important;
          }
        }
      }
    }
  }
}
