@mixin tranform($tranformValue, $transitionValue) {
  transform: $tranformValue;
  transition: $transitionValue;
}

@mixin notTranform {
  transition: transform 1s ease-in-out;
}

@mixin runToRight {
  animation: runToRight 1.7s ease-in-out;
}

@mixin aspearText {
  animation: aspearText 1s ease-in-out;
}

@mixin runTop {
  animation: runTop 0.5s ease-in-out;
}

// ANIMATION
@keyframes runToRight {
  0% {
    left: 20px;
  }
  50% {
    left: 30rem;
  }
  75% {
    left: -20rem;
  }
  100% {
    left: 20px;
  }
}

@keyframes aspearText {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes runTop {
  0% {
    margin-top: -20px;
  }
  100% {
    margin-top: 0;
  }
}
