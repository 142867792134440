.privacy-policy {
  background-color: white;

  .privacy-policy-container {
    margin-top: 4.7rem;
    padding: 15px;

    .privacy-policy-topic {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;

      .topic-content-container {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    }
  }
}
