.ImageCardCluster {
  width: 100%;
  height: 800px;
  padding: 50px 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  &__Container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    padding: 0 !important;

    &__Title {
      margin-left: 15px !important;
      font-weight: bold !important;
      z-index: 1;
    }

    &__Carousel {
      height: 460px;
      padding: 10px 0 !important;

      &__Slider {
        height: 100%;

        &__Item {
          width: 10px;
          height: 100%;
          padding: 0 15px !important;

          &.react-multi-carousel-item--active:first-child {
            padding-left: 0 !important;
          }

          &.react-multi-carousel-item--active:last-child {
            padding-right: 0 !important;
          }
        }
      }
    }

    &__ButtonContainer {
      display: flex;
      justify-content: center;
      &__Button {
        position: absolute;
        width: 200px;
        padding: 7px 0 !important;
        justify-content: center !important;
        border-radius: 50px !important;

        &__Text {
          padding: 0 12px;
          font-weight: 800 !important;
        }

        &__IconContainer {
          display: flex;
          width: 30px;
          height: 30px;
          background-color: #0f5500;
          border-radius: 50px;
          justify-content: center;
          align-items: center;

          &__Icon {
            margin-left: 5px;
          }
        }
      }
    }
  }
}
