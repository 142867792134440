@import 'assets/scss/core/variables';

.golf-tour-booking-card {
  overflow: unset !important;

  .card-title {
    margin-bottom: 1rem;
  }

  .booking-option {
    display: flex;
    justify-content: space-around;
    margin-bottom: 1rem;

    &__departure-picker,
    &__player-picker {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50%;
    }

    &__departure-picker .custom-departure-picker {
      width: 100%;
    }
  }

  .booking-price {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .old-price {
      font-size: 0.9rem;
      font-weight: 500;
      color: $gray-600;
      text-decoration: line-through;
    }

    .sale-price {
      font-weight: 500;
      color: $red;
    }
  }

  .card-actions {
    display: flex;
    justify-content: center;
  }

  .table-block {
    display: flex;
    flex: 1;
    justify-content: center;
  }

  .customIncrease {
    width: 50px;
    padding: 0 !important;
    margin: 0 0.5rem !important;
    vertical-align: unset !important;
    input {
      text-align: center;
      font-size: 18px;
      font-weight: 500;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.picker-menu-item {
  display: flex !important;
  flex-direction: column !important;
  border-bottom: 1px solid $gray-500 !important;

  &:last-child {
    border-bottom: none !important;
  }

  .picker-item-container {
    display: flex;
    width: 100%;
    cursor: pointer;
    justify-content: space-between;

    .picker-item-label {
      font-weight: 400;
      color: $gray-700;
    }

    .picker-item-value {
      font-weight: 500;
      color: $gray-800;
      margin-left: 3px;
    }
  }
}

.suggested-tours-subtitle {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  max-height: 60px;
}
