@import 'assets/scss/global-styles';

$DesktopContainerFontSize: 2.5rem !important;
$MobileContainerFontSize: 1.3rem !important;

$DesktopIconButtonSize: 4rem !important;
$MobileIconButtonSize: 2rem !important;

$Desktop_FrameHorizontalPadding: 60px !important;
$Mobile_FrameHorizontalPadding: 10px !important;

$DesktopButtonWidth: 30rem !important;
$MobileButtonWidth: 100% !important;

.SplitImageBanner {
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-height: calc(100vh - 4.7rem);
  margin-top: 4.7rem;

  &__ImageContainer {
    display: flex;
    position: relative;
    width: 100%;
    padding: 0 !important;
    height: calc((100vh - 4.7rem) / 3);
    background-color: white;
    justify-content: center;
    align-items: center;

    &.AutoResize {
      height: auto;
      max-height: calc((100vh - 4.7rem) / 3);
    }

    &.Border {
      border: 3px solid $grey-400;
    }

    &__Image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__Frame--top,
    &__Frame--bottom {
      display: flex;
      position: relative;
      width: 100%;
      height: 100%;

      &__Container {
        display: grid;
        height: 100%;
        align-content: space-around;
        justify-items: center;
        margin-right: $Desktop_FrameHorizontalPadding;
        margin-left: $Desktop_FrameHorizontalPadding;

        @media (max-width: 736px) {
          width: 100%;
          margin-right: $Mobile_FrameHorizontalPadding;
          margin-left: $Mobile_FrameHorizontalPadding;
        }

        &__Text {
          font-size: $DesktopContainerFontSize;
          font-weight: 900 !important;
          color: white !important;
          width: fit-content;
          text-shadow: 0 0 10px rgba(255, 255, 255, 0.4);

          @media (max-width: 736px) {
            font-size: $MobileContainerFontSize;
          }
        }

        &__Button {
          display: flex;
          max-height: 5rem;
          width: $DesktopButtonWidth;

          @media (max-width: 736px) {
            width: $MobileButtonWidth;
            min-width: 1px !important;
            padding: 1rem 1.5rem !important;
          }

          &__Icon {
            width: $DesktopIconButtonSize;
            height: $DesktopIconButtonSize;
            margin-top: -2rem;
            margin-right: 10px;

            @media (max-width: 736px) {
              width: $MobileIconButtonSize;
              height: $MobileIconButtonSize;
            }
          }

          &__Text {
            font-weight: 900 !important;
            padding: 0;
            font-size: 2vh !important;
            text-wrap: wrap !important;
            overflow-wrap: break-word;
            word-wrap: break-word;
          }
        }
      }
    }

    &__Frame--bottom {
      justify-content: flex-end;
      @media (max-width: 736px) {
        justify-content: unset;
      }
    }

    &__Carousel {
      width: 100%;
      height: 100%;

      &__Slider {
        height: 100%;
        max-height: calc((100vh - 4.7rem) / 3);
        &__Item {
          height: 100%;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
