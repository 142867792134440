.customer-information-block {
  padding: 0;

  h5 {
    margin: 0;
    font-weight: 400;
  }

  .CompleteBooking__Left__PersonInfo {
    margin-bottom: 2rem;
    .MuiGrid-container {
      margin-left: -15px;
      margin-right: -15px;
    }
    &__SurchargeContainer,
    &__HotDealContainer {
      margin-top: 1rem;
      &__SurchargeContainer__Checkbox {
        font-size: small;
      }
    }

    &__Vehicle {
      &__LocationValueContainer {
        margin-bottom: 5px !important;
        &__LocationValue {
          display: contents !important;
        }
      }
    }
  }
}

.complete-booking-action-block {
  text-align: center;
}
