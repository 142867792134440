@import 'assets/scss/core/variables';

$content-item-height: 40px;

.sub-title-info {
  width: 100%;

  .row-info,
  .row-address {
    display: flex;
    width: 100%;
    align-items: flex-end;
  }

  .row-address .icon {
    margin-bottom: 4px;
    margin-right: 2px;
    font-size: larger;
    color: $green-400;
  }

  .row-address .address {
    text-transform: capitalize;
  }

  .row-info .left {
    width: 100px;
    text-transform: capitalize;
  }

  .row-info .right {
    flex: 1;
    text-transform: capitalize;
  }
}

.tee-time-booking-card {
  overflow: unset !important;

  .card-title {
    margin-bottom: 1rem;
  }

  .booking-option {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 0.5rem;

    .booking-option__date-picker,
    .booking-option__player-picker {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50%;
    }

    .booking-option__date-picker .custom-date-picker {
      box-shadow: none !important;
      border: 1px solid $gray-900;
    }
  }

  .tee-time-table {
    height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;

    .table-header {
      display: flex;
      justify-content: space-around;
      position: sticky;
      top: 0;
      background-color: white;
      z-index: 1;
      padding: 0.5rem 0;
    }

    .table-content {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .not-found-item {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }

      .content-item {
        display: flex;
        justify-content: space-around;
        height: $content-item-height;
        box-shadow: none;
        padding: 0;
      }

      .skeleton-item {
        height: $content-item-height;
        width: 100%;
        margin-bottom: 0.5rem;
      }
    }
  }

  .card-actions {
    display: flex;
    justify-content: center;
  }

  .table-block {
    display: flex;
    flex: 1;
    justify-content: center;
  }
}
