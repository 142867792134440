@import 'assets/scss/global-styles.scss';

.scroll-to-top {
  position: fixed;
  z-index: 1000;
  bottom: 15px;
  right: 5px;
  animation: fadeIn 700ms ease-in-out 1s both;
  cursor: pointer;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.btn-scrollTop {
  display: grid;
  width: 70px;
  height: 70px;
  border-radius: 50px;
  background-color: white;
  box-shadow: 0 1px 10px rgba(130, 130, 134, 0.5);
  transition: 0.4s ease-in-out;

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
  }
}

.btn-scrollTop i {
  font-size: 1.5rem;
  padding-top: 30%;
  color: #000;
  transition: 0.4s ease-in-out;
  text-align: center;
}

.btn-scrollTop:hover {
  background-color: $success;
}

.btn-scrollTop:hover i {
  color: white;
  animation: mymove 1s infinite;
}

@keyframes mymove {
  0% {
    margin-top: 0px;
  }
  25% {
    margin-top: -6px;
  }
  50% {
    margin-top: -3px;
  }
  75% {
    margin-top: -4px;
  }
  100% {
    margin-top: 0px;
  }
}
