@import 'assets/scss/core/variables';

.ProductFlexCard {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 2rem !important;
  box-shadow: unset !important;

  &__Media {
    overflow: hidden;
    border-bottom-left-radius: 2rem !important;
    border-bottom-right-radius: 2rem !important;
    height: 230px !important;
    object-fit: fill !important;
  }

  &__Body {
    padding: 0.5rem 1rem !important;
  }

  &__Actions {
    display: flex;
    align-content: center;
    justify-content: space-between;
    padding: 0.5rem 1rem !important;

    &__PriceContainer {
      display: flex;
      width: 100%;
    }

    &__Price {
      font-weight: 600 !important;

      &.oldPrice {
        text-decoration: line-through;
      }

      &.newPrice {
        color: red;
        font-weight: bold;
        margin-left: 5px;
      }
    }

    &__Button {
      width: 100%;
      padding: 0.5rem 0 !important;
      border-radius: 2rem !important;
      font-weight: bold !important;

      &__Text {
        padding: 0 12px;
        font-weight: 800 !important;
        text-transform: capitalize !important;
      }

      &__IconContainer {
        display: flex;
        width: 20px;
        height: 20px;
        background-color: white;
        border-radius: 50px;
        justify-content: center;
        align-items: center;

        &__Icon {
          margin-left: 5px;
          color: #ff9800;
        }
      }
    }
  }
}
