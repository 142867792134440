.MainFrame {
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 0px;
  overflow-wrap: break-word;
  border-radius: 0.75rem;
  overflow: visible;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 1440px;
}
