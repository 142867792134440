$containerHeight: 300px;
$nextImageHeight: calc(300px / 2);
$borderStyle: 4px solid white;

.ImageCarousel {
  display: flex;
  margin-top: 4.7rem;
  height: $containerHeight;
  overflow: hidden;
  background-color: white;
  border: $borderStyle;

  img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
  }

  &__Carousel {
    width: 50%;
    height: $containerHeight;

    @media (max-width: 599px) {
      width: 100%;
    }

    &__Swiper {
      width: 100%;
      height: 100%;
    }
  }

  &__FullScreenLibrary {
    position: relative;
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    height: $containerHeight;

    &__Item {
      width: 50%;
      height: $nextImageHeight;
      overflow: hidden;
      position: relative;
      border-left: $borderStyle;
      border-bottom: $borderStyle;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__SeeAllButton {
      position: absolute !important;
      z-index: 1;
      right: 15px;
      bottom: 20px;
    }
  }
}

.Dialog {
  &__Paper {
    position: relative;
    background-color: white !important;
    justify-content: center;
    align-items: center;

    &__CarouselContainer {
      width: 100%;
      height: 754px;
      background-color: black;
    }

    &__CloseButton {
      position: absolute !important;
      z-index: 1;
      top: 10px;
      right: 20px;
    }
  }
}
