.ThumbsGlCarousel {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;

  &__WrapperContainer {
    height: 70%;
    &__Wrapper {
      width: 100%;
      height: 100%;
    }
  }

  &__WatchWrapperContainer {
    height: 20%;

    &__WatchWrapper {
      width: 100%;
      height: 100%;

      .swiper-slide {
        cursor: pointer;
      }

      .swiper-slide::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
      }

      .swiper-slide-thumb-active::before {
        background: rgba(0, 0, 0, 0);
      }
    }
  }

  img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
  }
}

.ActiveThumb {
  border: 5px solid #000;
}
