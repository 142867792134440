.BlockSection {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 5rem;

  img {
    width: 100%;
    height: auto;
  }

  .SuggestedProducts {
    margin-top: 5rem;
    width: 100%;
  }
}
