.complete-booking-dialog-page {
  width: 100% !important;
  justify-content: center;
  .MuiGrid-grid-md-7,
  .MuiGrid-grid-md-5 {
    padding: 0;
  }

  .left-block {
  }

  .right-block {
    padding: 0;
    margin-top: 30px;
    @media (min-width: 959px) {
      margin-left: 3rem;
    }
    .CompleteBooking__Right__DetailBody {
      padding-right: 15px;
      padding-left: 15px;
      .CompleteBooking__Right__BookingDetail {
        margin-top: 1rem;
        display: flex;
        justify-content: space-around;
        .CompleteBooking__Right__BookingDetail__DateChoose,
        .CompleteBooking__Right__BookingDetail_TimeChoose {
          width: fit-content;
          display: flex;
          place-items: center;
          > div {
            margin-left: 0.5rem;
            > p.bold {
              font-weight: 400;
            }
            > p {
              margin-bottom: 0;
              line-height: 1.5em;
            }
          }
        }
      }
    }

    .CompleteBooking__Right__Receipt {
      margin-top: 1rem;
      table {
        width: 100%;
        td.CompleteBooking__Right__Receipt__AlignRight {
          text-align: right;
          p {
            margin: 0;
            font-weight: 400;
          }
        }
      }
      .CompleteBooking__Right__Receipt__SurchargeItem {
        td {
          font-size: 14px;
          font-style: italic;
          padding-left: 1rem;
          p {
            font-size: 14px;
            font-weight: unset;
          }
        }
      }
      .CompleteBooking__Right__Receipt__ReceiptLineBlock {
        td {
          .receiptLine {
            border-top: 1px solid black;
          }
        }
      }
      .CompleteBooking__Right__Receipt__TotalBlock {
        td,
        td p {
          font-weight: 500 !important;
        }
      }
    }
  }
}
