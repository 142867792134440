.OurPartner {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: white;
  padding: 4rem 0;

  &__Container {
    &__Title {
      font-weight: bold !important;
      text-align: center !important;
    }

    &__LogoContainer {
      justify-content: space-between;
      display: flex;
      flex-wrap: wrap;

      &__GridItemLogo {
        display: flex;
        flex-direction: column;
        flex-basis: content;
        align-items: center;
        margin-top: 20px !important;
        padding: 0 !important;
        justify-content: center;

        @media (max-width: 629px) {
          min-width: calc(100% / 3);
        }

        &__Container {
          align-items: center;
          width: 100%;

          &__Title {
            font-weight: bold !important;
            margin-bottom: 0 !important;
            font-size: 0.7rem !important;
          }

          &__LogoBlock {
            border-radius: 100px;
            width: 150px;
            height: 130px;
            padding: 20px;

            @media (max-width: 329px) {
              width: 80px;
              height: 80px;
              padding: 10px;
            }

            &__Logo {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }

    &__PaymentMethodContainer {
      display: flex;
      flex-direction: column;
      margin-top: 40px !important;

      &__Title {
        font-weight: bold !important;
      }

      &__LogoContainer {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px !important;
        justify-content: space-around;

        &__GridItemLogo {
          display: flex;
          width: 100px;

          @media (max-width: 768px) {
            width: 15%;
          }

          &__Logo {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
}
