@import 'assets/scss/global-styles.scss';

.Footer {
  border-radius: 0;
  padding: 4rem 0;

  &__SocialNetwork {
    display: flex;
    flex-direction: column;

    &__SubscribeTitleGroup {
      justify-content: center;
      align-items: baseline;
      &__TextContainer {
        width: fit-content !important;
        padding: 0 !important;

        &__Title {
          margin-right: 3px !important;
          @media (max-width: 320px) {
            font-size: 1.8rem !important;
          }
        }

        &__Example {
          @media (max-width: 320px) {
            font-size: 0.8rem !important;
          }
        }
      }
    }

    &__SubscribeForm {
      display: flex;
      justify-content: center;
      margin-top: 0.5rem;

      &__FormControl {
        padding-top: 0 !important;
        width: 20rem !important;
        margin-right: 5px !important;
      }
    }

    &__VisitUsOnContainer {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 1rem;
      display: flex;

      &__VisitUsOn {
        display: flex;
        align-items: center;
        height: auto;
        justify-content: space-between;

        &__Icon,
        &__Tooltip {
          width: 10% !important;
          max-width: 70px;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }

  &__CompanyInfo {
    margin-top: 3rem;

    &__Left {
      padding: 0 !important;
      @media (min-width: 600px) {
        padding-left: 0 !important;
        padding-right: 15px !important;
      }

      &__Container {
        display: flex;
        width: 100%;
        justify-content: space-between;

        &__Name {
          display: flex;
          align-items: center;
          margin-bottom: 1rem;
          width: 48%;

          &__Logo {
            width: 100%;
            padding: 10px;
            height: 100%;

            &.white {
              background-color: white;
            }

            &.black {
              background-color: black;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          &__Text {
            text-align: center;
            margin-left: 0.5rem !important;
          }
        }
      }

      &__Address {
        margin-bottom: 1rem;

        &__Block {
          display: flex;

          &__Text {
            display: flex;
            text-align: center;
            text-transform: unset;
            font-weight: 500 !important;
          }
        }
      }

      &__Phone {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        &__TextContainer {
          display: flex;
          flex-direction: column;
          width: 100%;
          align-items: center;
          transition: all 0.2s ease-in-out;

          &__Text {
            color: black !important;

            &:hover {
              color: #00acc1 !important;
              text-shadow: 0 0 1px #00acc1 !important;
              text-decoration: underline !important;
            }
          }
        }
      }
    }

    &__Right {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 !important;

      @media screen and (min-width: 600px) {
        border-left: 1px solid $gray-400;
        padding-left: 15px !important;
      }

      @media screen and (min-width: 600px) {
        padding-right: 0 !important;
      }

      &__GroupContainer {
        display: flex;
        padding: 0 !important;

        &__Group {
          padding: 0 !important;

          &__MenuList {
            display: flex;
            flex-direction: column;
            padding: 0 !important;
            width: 100%;

            &__GroupTitle {
              @media (max-width: 320px) {
                font-size: 1rem !important;
              }
            }

            &.centered {
              align-items: center;
            }

            &__MenuItem {
              display: flex;
              align-items: center;
              padding-left: 0 !important;
              max-width: 30rem !important;
              text-wrap: wrap !important;
              padding-top: 3px !important;
              padding-bottom: 3px !important;
              min-height: unset !important;
              background-color: transparent !important;

              &.centered {
                text-align: center !important;
              }

              &:hover {
                background-color: transparent !important;
              }

              &:active {
                background-color: transparent !important;
              }

              &__Text {
                color: unset;
                font-size: 0.75rem !important;
                font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
                font-weight: 400 !important;
                line-height: 1.66 !important;
                letter-spacing: 0.03333em !important;
                transition: all 0.2s ease-in-out !important;
                float: left;
                text-align: left;

                &.centered {
                  text-align: center;
                }

                @media (max-width: 320px) {
                  font-size: 0.6rem !important;
                }

                &:hover {
                  color: #00acc1;
                  text-shadow: 0 0 1px #00acc1 !important;
                  text-decoration: underline;
                }
              }

              a {
                color: unset;
                font-size: 0.75rem;
                font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
                font-weight: 400;
                line-height: 1.66;
                letter-spacing: 0.03333em;
                transition: all 0.2s ease-in-out;

                &:hover {
                  color: #00acc1;
                  text-shadow: 0 0 1px #00acc1 !important;
                  text-decoration: underline;
                }
              }

              &__Label {
                margin-right: 2px !important;
              }

              &__Spacing {
                margin: 0 2px !important;
              }
            }
          }
        }
      }
    }
  }
}
