@import 'assets/scss/core/variables';

.travel-booking-card {
  overflow: unset !important;

  .card-title {
    margin-bottom: 1rem;
  }

  .booking-option {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 2rem;

    .booking-option__date-picker,
    .booking-option__player-picker {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50%;
    }

    .booking-option__date-picker .custom-date-picker {
      box-shadow: none !important;
      border: 1px solid $gray-900;
    }
  }

  .booking-price {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .old-price {
      font-size: 0.9rem;
      font-weight: 500;
      color: $gray-600;
      text-decoration: line-through;
    }

    .sale-price {
      font-weight: 500;
      color: $red;
    }
  }

  .card-actions {
    display: flex;
    justify-content: center;
  }

  .table-block {
    display: flex;
    flex: 1;
    justify-content: center;
  }

  .customIncrease {
    width: 50px;
    padding: 0 !important;
    margin: 0 0.5rem !important;
    vertical-align: unset !important;
    input {
      text-align: center;
      font-size: 18px;
      font-weight: 500;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
